import styled from "styled-components"

import { TSettings } from "src/components/Integrations/IntegrationConnectDialogs/ThermostatSettingsDialog"
import { ThermostatTemperatureInput } from "src/components/Integrations/IntegrationConnectDialogs/ThermostatTemperatureInput"
import { TThermostatMode } from "src/data/homes/types/homeTypes"
import { translateThermostatMode } from "src/data/integrations/logic/integrations"
import { TAvailableThermostatMode } from "src/data/integrations/types/integrationTypes"
import { TTemperatureUnit } from "src/data/user/user"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MDivider } from "src/ui/Divider/Divider"
import { MSelect, TMSelectOptions } from "src/ui/MSelect/MSelect"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

const MAX_PRE_HEAT_COOL_TIME = 720 // in minutes
const MIN_PRE_HEAT_COOL_TIME = 60 // in minutes

export function ThermostatSettings({
  temperatureControlValues,
  setTemperatureControlValues,
  temperatureUnit,
  selectedMode,
  setSelectedMode,
  commonAvailableModes,
}: {
  temperatureControlValues: TSettings
  setTemperatureControlValues: (value: TSettings) => void
  temperatureUnit: NonNullable<TTemperatureUnit>
  selectedMode?: TThermostatMode
  setSelectedMode: (value?: TThermostatMode) => void
  commonAvailableModes?: TAvailableThermostatMode[]
}) {
  const { t } = useTranslate()

  const temperatureDelta = temperatureUnit === "C" ? 3 : 6

  const options: TMSelectOptions =
    commonAvailableModes?.map((mode) => ({
      label: translateThermostatMode(mode, t),
      value: mode,
    })) || []

  return (
    <div>
      <SubSectionBox>
        <div>
          <MText variant="subtitle">
            {t(langKeys.integrations_thermostat_mode_title)}
          </MText>
          <MText color="secondary">
            {t(langKeys.integrations_thermostat_mode_description)}
          </MText>
        </div>

        <MSelect
          label={t(langKeys.integrations_thermostat_mode_title)}
          options={options}
          value={selectedMode || ""}
          onChange={(value) => setSelectedMode(value as TThermostatMode)}
        />
      </SubSectionBox>

      <StyledDivider />

      <SubSectionBox>
        <div>
          <MText variant="subtitle">{t(langKeys.temperature)}</MText>
          <MText color="secondary">
            {selectedMode === "heatcool"
              ? `${t(langKeys.integrations_thermostat_temperature_description)} ${t(
                  langKeys.thermostat_settings_temperature_delta,
                  {
                    delta: temperatureDelta,
                    unit: temperatureUnit,
                  }
                )}`
              : t(
                  langKeys.thermostat_settings_heat_and_cool_temperature_description
                )}
          </MText>
        </div>

        <ThermostatTemperatureInput
          selectedMode={selectedMode}
          temperatureControlValues={temperatureControlValues}
          setTemperatureControlValues={setTemperatureControlValues}
          temperatureUnit={temperatureUnit}
          temperatureDelta={temperatureDelta}
        />
      </SubSectionBox>

      <StyledDivider />

      <SubSectionBox>
        <div>
          <MText variant="subtitle">
            {t(langKeys.integrations_thermostat_settings_preheat_cool_time)}
          </MText>
          <MText color="secondary">
            {t(langKeys.integrations_thermostat_precool_heat_description)}
          </MText>
        </div>

        <MTextField
          type="number"
          min={MIN_PRE_HEAT_COOL_TIME / 60}
          max={MAX_PRE_HEAT_COOL_TIME / 60}
          label={`${t(langKeys.integrations_thermostat_settings_preheat_cool_time)} (${t(langKeys.hours).toLowerCase()})`}
          value={temperatureControlValues.preHeatCoolTime.toString()}
          onChange={(value) =>
            setTemperatureControlValues({
              ...temperatureControlValues,
              preHeatCoolTime: Number(value),
            })
          }
        />
      </SubSectionBox>
    </div>
  )
}

const StyledDivider = styled(MDivider)`
  margin: ${spacing.L} 0;
`

const SubSectionBox = styled.div`
  display: grid;
  gap: ${spacing.L};
`

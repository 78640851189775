import styled from "styled-components"

import { TSettings } from "src/components/Integrations/IntegrationConnectDialogs/ThermostatSettingsDialog"
import { breakpoint } from "src/constants/breakpoints"
import { TThermostatMode } from "src/data/homes/types/homeTypes"
import { TTemperatureUnit } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"
import { Container } from "src/ui/Container/Container"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"
import { convertTemperatureValue } from "src/utils/l10n"

export function ThermostatTemperatureInput({
  selectedMode,
  temperatureControlValues,
  setTemperatureControlValues,
  temperatureUnit,
  temperatureDelta,
}: {
  selectedMode?: TThermostatMode
  temperatureControlValues: TSettings
  setTemperatureControlValues: (value: TSettings) => void
  temperatureUnit: NonNullable<TTemperatureUnit>
  temperatureDelta: number
}) {
  const { t, langKeys } = useTranslate()

  // The heat minmax(16,26) and cool minmax(20,30) are the only acceptable values for now
  const HEAT_TEMP_MIN = Number(convertTemperatureValue(16, temperatureUnit, 0))
  const HEAT_TEMP_MAX = Number(convertTemperatureValue(26, temperatureUnit, 0))
  const COOL_TEMP_MIN = Number(convertTemperatureValue(20, temperatureUnit, 0))
  const COOL_TEMP_MAX = Number(convertTemperatureValue(30, temperatureUnit, 0))

  const showHeatInput = selectedMode === "heatcool" || selectedMode === "heat"
  const showCoolInput = selectedMode === "heatcool" || selectedMode === "cool"

  function handleBlurHeatTemperature(value: number) {
    const clampedHeatTemp = Math.max(
      HEAT_TEMP_MIN,
      Math.min(value, HEAT_TEMP_MAX)
    )

    const clampedCoolTemp = Math.min(
      Math.max(
        temperatureControlValues.coolTemperature,
        clampedHeatTemp + temperatureDelta
      ),
      COOL_TEMP_MAX
    )

    setTemperatureControlValues({
      ...temperatureControlValues,
      heatTemperature: clampedHeatTemp,
      coolTemperature: clampedCoolTemp,
    })
  }

  function handleBlurCoolTemperature(value: number) {
    const clampedCoolTemp = Math.max(
      COOL_TEMP_MIN,
      Math.min(value, COOL_TEMP_MAX)
    )

    const clampedHeatTemp = Math.max(
      Math.min(
        temperatureControlValues.heatTemperature,
        clampedCoolTemp - temperatureDelta
      ),
      HEAT_TEMP_MIN
    )

    setTemperatureControlValues({
      ...temperatureControlValues,
      coolTemperature: clampedCoolTemp,
      heatTemperature: clampedHeatTemp,
    })
  }
  return (
    <Container>
      <TemperatureBox
        $showHeatInput={showHeatInput}
        $showCoolInput={showCoolInput}
      >
        {showHeatInput && (
          <MTextField
            type="number"
            min={HEAT_TEMP_MIN}
            max={HEAT_TEMP_MAX}
            label={
              selectedMode === "heatcool"
                ? `${t(langKeys.min_temperature)} (°${temperatureUnit})`
                : `${t(langKeys.temperature)} (°${temperatureUnit})`
            }
            value={temperatureControlValues.heatTemperature.toString()}
            onChange={(value) =>
              setTemperatureControlValues({
                ...temperatureControlValues,
                heatTemperature: parseInt(value, 10),
              })
            }
            onBlur={() => {
              handleBlurHeatTemperature(
                temperatureControlValues.heatTemperature
              )
            }}
            error={!!(temperatureControlValues.heatTemperature >= 27)}
            hidden={true}
          />
        )}

        {showCoolInput && (
          <MTextField
            type="number"
            min={COOL_TEMP_MIN}
            max={COOL_TEMP_MAX}
            label={
              selectedMode === "heatcool"
                ? `${t(langKeys.max_temperature)} (°${temperatureUnit})`
                : `${t(langKeys.temperature)} (°${temperatureUnit})`
            }
            value={temperatureControlValues.coolTemperature.toString()}
            onChange={(value) =>
              setTemperatureControlValues({
                ...temperatureControlValues,
                coolTemperature: parseInt(value, 10),
              })
            }
            onBlur={() =>
              handleBlurCoolTemperature(
                temperatureControlValues.coolTemperature
              )
            }
          />
        )}
      </TemperatureBox>
    </Container>
  )
}

const TemperatureBox = styled.div<{
  $showHeatInput: boolean
  $showCoolInput: boolean
}>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.$showHeatInput && props.$showCoolInput ? "1fr 1fr" : "1fr"};
  width: 100%;
  gap: ${spacing.M};

  @container (${breakpoint.smallDown}) {
    grid-template-columns: 1fr;
  }
`
